export class CustomDate {
    constructor(date) {
        this.date = date;
    }

    static getCurrentDate() {
        return new CustomDate(new Date());
    }

    static fromDate(date) {
        let formatDate = new CustomDate(new Date(date));
        return formatDate;
    }

    static fromStringToDate(stringDate) {
        let formatDate = this.getCurrentDate();
        formatDate.setStringDate(stringDate);
        return formatDate;
    }

    getStringDate(){
        const day = String(this.date.getDate()).padStart(2, '0');
        const month = String(this.date.getMonth() + 1).padStart(2, '0');
        const year = this.date.getFullYear();        
        return `${day}/${month}/${year}`;
    }

    getDate() {
        return this.date;
    }

    setDate(date) {
        this.date = date;
    }

    setStringDate(stringDate) {
        const [day, month, year] = stringDate.split('/');
        this.date = new Date(year, month - 1, day);
        return this;
    }

    startDay() {
        this.date.setHours(0, 0, 0, 0);
        return this;
    }

    plusDays(days) {
        this.date.setDate(this.date.getDate() + days);
        return this;
    }

    minusDays(days) {
        this.date.setDate(this.date.getDate() - days);
        return this;
    }
}