import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardTitle, CardBody } from '@progress/kendo-react-layout';
import { useSelector, useDispatch } from 'react-redux'
import { selectMessageSelected, selectMessageParametersConfig, selectCampaignDetails } from './../../../../../../redux/whatsAppConnect'
import { setMessageParametersConfig, setCampaignDetails, selectWhatsAppConnect, setEmailsWithAccess } from './../../../../../../redux/whatsAppConnect'
import { saveNewWhatsAppConnectCampaign } from '../requestService';

const StepTwo = ({ messageFilter, prevStep, nextStep, addNotification, removeNotification }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const messageSelected = useSelector(selectMessageSelected);
    const messageParametersConfig = useSelector(selectMessageParametersConfig)
    const campaignDetails = useSelector(selectCampaignDetails)
    const whatsAppConnect = useSelector(selectWhatsAppConnect)
    const [campaign_image, setimage] = useState(true);
    const templateDetailsToShow = [
        {
            name: "Template Name",
            label: "Nombre de plantilla"
        }, {
            name: "Category",
            label: "Categoria"
        }, {
            name: "División",
            label: "División"
        }, {
            name: "Channel",
            label: "Canal"
        }, {
            name: "Solicitado por:",
            label: "Solicitado Por"
        }
    ]
    const campaignCreationForm = [
        {
            label: "Area",
            type: "text"
        }, {
            label: "Fecha de inicio",
            type: "date"
        }, {
            label: "Fecha de fin",
            type: "date"
        }, {
            label: "Responsable",
            type: "text"
        }
    ]
   
    const generateIntraDates = (data) => {
        data = data.map((el, index) => {
            if (el.datesInformation == undefined) {
                el.datesInformation = {}
                const start = new Date(el.campaignDetails.fecha_de_inicio)
                const end = new Date(el.campaignDetails.fecha_de_fin)
                for (let i = start; i <= end; i.setDate(i.getDate() + 1)) {
                    el.datesInformation[i.toISOString().split("T")[0]] = {
                        status: "Pendiente",
                        priority: 999999,
                        uploadedData: [],
                        generatedSuccess: [],
                        generatedFail: [],
                        canUpload: {
                            status: true,
                            closedAt: null,
                            closedBy: null,
                        }
                    }
                }
                return el
            } else {
                return el
            }
        })
        return data;
    }

    const extractNumbers = (template) => {
        const regex = /{{(\d+)}}/g;
        const numbers = [];
        let match;

        while ((match = regex.exec(template))) {
            const number = parseInt(match[1], 10);
            numbers.push(number);
        }
        return numbers;
    };

    const updateParameterNames = (index, newValue) => {
        let currentParameters = [...messageParametersConfig]
        currentParameters[index] = {
            ...currentParameters[index],
            parameterName: newValue
        }
        dispatch(setMessageParametersConfig(currentParameters))
    }

    const updateParameterExamples = (index, newValue) => {
        let currentParameters = [...messageParametersConfig]
        currentParameters[index] = {
            ...currentParameters[index],
            parameterExample: newValue
        }
        dispatch(setMessageParametersConfig(currentParameters))
    }

    const applyParametersToTemplate = (message) => {
        messageParametersConfig.forEach(messageConfig => {
            if (messageConfig.parameterExample.trim().length != 0) {
                message = message.replace(`{{${messageConfig.parameterNumber}}}`, `<span class="whatsappConnectParameterRendered">${messageConfig.parameterExample}</span>`)
            }
        })
        return message.split(/\r\n|\r|\n/).map(el => {
            return `<p>${el}</p>`
        }).join("")
    }

    const updateCampaignDetails = (field, newValue) => {
        let currentCampaignDetails = { ...campaignDetails }
        currentCampaignDetails[field] = newValue
        dispatch(setCampaignDetails(currentCampaignDetails))
    }

    const checkFormValues = () => {
        let areFieldsDone = true;
        const currentCampaignDetails = { ...campaignDetails }
        console.log(currentCampaignDetails)
        for (const detail in currentCampaignDetails) {
            if (Object.hasOwnProperty.call(currentCampaignDetails, detail)) {
                const element = currentCampaignDetails[detail];
                if (element.trim().length == 0) {
                    areFieldsDone = false;
                }
            }
        }

        for (const detail in messageParametersConfig) {
            if (Object.hasOwnProperty.call(messageParametersConfig, detail)) {
                const element = messageParametersConfig[detail];
                if (element.parameterName.trim().length == 0 || element.parameterExample.trim().length == 0) {
                    areFieldsDone = false;
                }
            }
        }

        if (areFieldsDone) {
            let whatsAppConnectFilled = JSON.parse(JSON.stringify(whatsAppConnect))
            whatsAppConnectFilled.emailsWithAccess.push(whatsAppConnectFilled.campaignMetaData.email)
            generateIntraDates([whatsAppConnectFilled]);
            saveNewWhatsAppConnectCampaign(whatsAppConnectFilled, hadleCampaignSave)
        } else {
            fieldsError();
            alert("Revisa los campos requeridos")
        }
    }

    const hadleCampaignSave = (data) => {
        // Trigger a success notification
        addNotification('success', data.data.message);
        // Remove the notification after 10 seconds
        setTimeout(() => {
            removeNotification({ type: 'success', message: data.data.message });
        }, 10000); // Remove after 10 seconds (10,000 milliseconds)
        nextStep();
    }

    const fieldsError = (data) => {
        // Trigger a success notification
        addNotification('error', "Revisa los campos del formulario");

        // Remove the notification after 10 seconds
        setTimeout(() => {
            removeNotification({ type: 'error', message: "Revisa los campos del formulario" });
        }, 10000); // Remove after 10 seconds (10,000 milliseconds)
    }

    useEffect(() => {
        const numbersInMessage = extractNumbers(messageSelected.Content);

        let messageParametersConfig = numbersInMessage.map(number => ({
            parameterNumber: number,
            parameterName: "",
            parameterExample: ""
        }));

        dispatch(setMessageParametersConfig(messageParametersConfig));

        setLoading(false);
    }, [])
    
    const low_render_image = (messageSelected) => {
        let txt = String(messageSelected['HTML IMAGEN'])
        return txt.slice(0, txt.indexOf('/>'))+'style="width: 250px;" />'
    }
    return (
        loading ? <>Cargando</> : <>
            <Card>
                <CardHeader>
                    <CardTitle>
                        <b>Paso 2 - Configuracion de mensaje</b>
                        <button className='WhatsAppConnect-cardHeaderAction WhatsAppConnect-success' onClick={() => {
                            checkFormValues();
                        }}>Guardar</button>
                        <button className='WhatsAppConnect-cardHeaderAction WhatsAppConnect-warning' onClick={() => {
                            prevStep();
                        }}>Regresar</button>
                    </CardTitle>
                </CardHeader>
                <CardBody className='WhatsAppConnect-templateDetails'>
                    {
                        templateDetailsToShow.map(field => {
                            return (
                                <div className='gridCell'>
                                    <b>{field.label}</b>
                                    <div>
                                        {messageSelected[field.name]}
                                    </div>
                                </div>
                            )
                        })
                    }
                    {
                        campaignCreationForm.map(field => {
                            return (
                                <div className='gridCell'>
                                    <b>{field.label}</b>
                                    <div>
                                        <input
                                            type={field.type}
                                            value={campaignDetails[(field.label).toLowerCase().split(" ").join("_")]}
                                            min={(field.type == "date") ? new Date().toISOString().split("T")[0] : ""}
                                            onChange={(e) => {
                                                updateCampaignDetails((field.label).toLowerCase().split(" ").join("_"), (e.target.value).trim())
                                            }}
                                        />
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className='gridCell'>
                        <b>Correos con acceso:</b>
                        <textarea value={(whatsAppConnect.emailsWithAccess).join("\n")} onChange={(ev) => {
                            const emails = (ev.target.value).replaceAll(",", "\n").split("\n")
                            dispatch(setEmailsWithAccess(emails))
                        }} />
                    </div>
                    <div className='gridCell'>
                        <b>Plantilla:</b>
                        <div className='whatsapp-message-white' dangerouslySetInnerHTML={{
                            __html: messageSelected.Content.split(/\r\n|\r|\n/).map(el => {
                                return `<p>${el}</p>`
                            }).join("")
                        }}>
                        </div>
                        <div className='whatsapp-message-white' dangerouslySetInnerHTML={{ __html: low_render_image(messageSelected)}}></div>
                    </div>
                    <div className='gridCell'>
                        <div>
                            <table className='whatsAppConnect-templateInputs'>
                                <thead>
                                    <tr>
                                        <th>
                                            ID
                                        </th>
                                        <th>
                                            Nombre
                                        </th>
                                        <th>
                                            Ejemplo
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        messageParametersConfig.map((parameter, index) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        {parameter.parameterNumber}
                                                    </td>
                                                    <td>
                                                        <input
                                                            value={parameter.parameterName}
                                                            onChange={(e) => {
                                                                updateParameterNames(index, e.target.value)
                                                            }} />
                                                    </td>
                                                    <td>
                                                        <input value={parameter.parameterExample}
                                                            onChange={(e) => {
                                                                updateParameterExamples(index, e.target.value)
                                                            }} />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='gridCell'>
                        <b>Previsualizacion:</b>
                        <div className="whatsapp-message-green">
                            <div className='' dangerouslySetInnerHTML={{ __html: applyParametersToTemplate(messageSelected.Content) }}></div>
                            <div className='' dangerouslySetInnerHTML={{ __html: low_render_image(messageSelected)}}></div>
                        </div>
                    </div>
                </CardBody>
            </Card >
        </>
    )
}

export { StepTwo }